/*@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;500;700&family=Red+Hat+Text:ital,wght@0,400;0,500;1,400&display=swap');*/


/*body {*/
/*  margin: 0;*/
/*  font-family: 'Red Hat Display',  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*  sans-serif;*/
/*  background-color: #F0F0F0;*/
/*  color: #0a0a0a;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  font-size: 16px;*/
/*  background-repeat: repeat;*/
/*}*/

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
  /*monospace;*/
}

@keyframes animateInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 2%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animateInUp {
  animation-name: animateInUp;
}

.fade-enter {
  opacity: 0.00;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.tooltip-blur {
  backdrop-filter: blur(4px);
  width: auto;
}
.ant-tooltip-inner {
  text-align: center;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 1%;
}

.dropdown-menu {
  max-height: 30vw;
  overflow-y: scroll;
}

.part-dropdown-menu {
  max-height: 300px;
  overflow-y: scroll;
}

.router-on-hold {
  background-color: rgba(240, 240, 240);
}

.router-ship-schedule-ontime {
  background-color: rgba(219, 239, 220, 0.8);
}
.router-ship-schedule-ontime > .ant-table-cell-fix-left {
  background-color: rgba(219, 239, 220, 0.8);
}
.router-ship-schedule-ontime > .ant-table-cell-fix-right {
  background-color: rgba(219, 239, 220, 0.8);
}
.router-ship-schedule-ontime:hover > td {
  background-color: rgba(219, 239, 220, 0.8);
}

.router-ship-schedule-ontime> .ant-table-column-sort {
  background-color: rgb(219, 239, 220);
}

.router-ship-schedule-behind {
  border-bottom: 1px solid rgb(250, 250, 250);
  background-color: rgb(252, 217, 214);
}
.router-ship-schedule-behind > .ant-table-cell-fix-left {
  background-color: rgba(252, 217, 214, 0.8);
}
.router-ship-schedule-behind > .ant-table-cell-fix-right {
  background-color: rgba(252, 217, 214, 0.8);
}
.router-ship-schedule-behind> .ant-table-column-sort {
  background-color: rgb(252, 217, 214);
}

.router-ship-schedule-warning {
  border-bottom: 1px solid rgb(250, 250, 250);
  background-color: rgb(255, 234, 204);
}

.router-ship-schedule-warning > .ant-table-column-sort {
  background-color: rgb(255, 234, 204);
}
.ant-table-tbody > tr > td {
  /*border-bottom: 2px solid rgb(250, 250, 250);*/
  text-align: center;

  height: 24px;
  padding: 0px 8px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  cursor: pointer;
}

/*@media (max-width: 1441px) {*/
.ant-table-tbody > .sales-order-row {
  height: 28px;
}

.ant-table-tbody > .sales-order-prev-nav {
  background-color: lightyellow !important;
}

.ant-table-column-sort {
  background: inherit !important;
}

.ant-table-tbody > .sales-order-row-bordered > td {
  height: 28px;
  border-left: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey;
}

.ant-table-tbody > .sales-order-row > td{
  height: 28px;
  border-top: 1.5px solid lightgrey;
}

.ant-table-tbody > .nc-standard-row {
  height: 36px;
}

/*@media (max-width: 1441px) {*/
.ant-table-tbody > .nc-highlight-row {
  height: 36px;
  background-color: lightgoldenrodyellow;
}

.ant-table-tbody > .sales-order-row:not(.sales-order-row-clicked):nth-child(2n) {
  background-color: rgba(0,0,0,0.02);
}

.ant-table-tbody > .sales-order-parent-row:not(.sales-order-row-clicked):nth-child(2n) {
  background-color: rgba(0,0,0,0.02);
}
.ant-table-tbody > .sales-order-parent-row.clickable-row {
  box-shadow: inset 0 2px 0 0 slategray;
  height: 60px;
}
.ant-table-tbody > .sales-order-parent-row.clickable-row.pricing-record-inactive {
  box-shadow: inset 0 2px 0 0 slategray, inset 4px 0 0 0 rgba(255, 152, 0, 1);
}
.ant-table-tbody > .sales-order-row.clickable-row {
  height: 48px;
}
.ant-table-tbody > .sales-order-row.clickable-row:hover {
  background-color: rgba(30, 133, 211, 0.1);
}

.ant-table-row.pricing-record-inactive {
  box-shadow: inset 6px 0 0 0 rgba(255, 152, 0, 1);
}

.ant-table-tbody > .sales-order-row-clicked {
  background-color: rgba(24, 144, 255, 0.3);
}
.ant-table-tbody > .sales-order-row-clicked > .ant-table-column-sort {
  background-color: rgba(24, 144, 255, 0.3);
}

.ant-table.ant-table-small > tr > td {
  padding: 8px 8px;
}

.ant-table-thead > tr > th {
  text-align: center;
}

.ant-table.ant-table-small .ant-table-title, .ant-table.ant-table-small .ant-table-footer, .ant-table.ant-table-small .ant-table-thead > tr > th, .ant-table.ant-table-small .ant-table-tbody > tr > td, .ant-table.ant-table-small tfoot > tr > th, .ant-table.ant-table-small tfoot > tr > td {
  padding: 0px 8px;
}

.ant-tooltip {
  max-width: unset;
}

.ant-spin-container > .ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin-left: 0;
}

@media print {
  .ant-table-tbody > tr > td {
    /*border-bottom: 2px solid rgb(250, 250, 250);*/
    text-align: center;

    height: 18px;
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }
}

.ant-popover-message {
  display: flex;
}

.ant-popover-inner {
  border-radius: 12px;
}

.ant-popover-message > .ant-popover-message-title {
  padding-left: 0;
}

.ant-popover-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ant-popover-buttons > button {
  margin-left: 0;
}

.ant-popover-buttons > button:first-of-type {
  margin-right: 8px;
}

.backorder-text {
  background-color: #0b8cff;
  color: #FAFAFA;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 0;
}
.time-study-text {
  padding: 4px 8px;
  background-color: tomato;
  color: #FAFAFA;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 0;
}

.block-and-bound-text {
  padding: 4px 8px;
  background-color: teal;
  color: #FAFAFA;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  margin-bottom: 0;
}

.compound-radius-text {
  padding: 4px 8px;
  background-color: #b7eb8f;
  color: #0A0A0A;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
}

.weight-reduce-text {
  padding: 4px 8px;
  color: black;
  background-color: #ffd608;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
  /*animation: blinker 1s step-start infinite;*/
}
.topped-text {
  font-weight: 500;
  background-color: #e000d9;
  color: #FAFAFA;
  padding: 2px 4px;
  border-radius: 4px;
}

.tiltback-text {
  padding: 4px 8px;
  background-color: #870083;
  color: #FAFAFA;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
}

.house-sample-text {
  padding: 4px 8px;
  background-color: #4087c5;
  color: #FAFAFA;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.prototype-text {
  padding: 4px 8px;
  background-color: rgba(255, 255, 0, 0.25);
  color: #0A0A0A;
  font-weight: 500;
  display: inline-block;
  border-radius: 4px;
}

.order-date-text {
  font-weight: 500;
  color: black;
  background-color: rgba(0,0,0,0.05);
  padding: 2px 4px;
  border-radius: 4px;
  font-size: 16px;
}
.hold-text {
  font-weight: 500;
  color: white;
  background-color: rgba(0,0,0,0.7);
  padding: 2px 4px;
  border-radius: 4px;
}
.no-finish-text {
  font-weight: 500;
  color: black;
  background-color: #ffa4a4;
  padding: 2px 4px;
  border-radius: 4px;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: unset;
}

.data-copy-notification {
  background-color: #1e85d3;
}

.data-download-notification {
  background-color: #4CAF50;
}

.data-copy-error {
  background-color: #ff4d4f;
}

.ant-notification-notice-message {
  font-weight: bolder;
  color: #FFFFFF;
}

.ant-notification-notice-description {
  font-size: 16px;
  color: #FFFFFF;
  line-height: 18px;
  margin-top: -4px;
}

.ant-notification-notice-close {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  font-weight: bold;
}

.ant-notification-notice-close:hover {
  transition: color 0.3s ease-in;
  color: rgba(255, 255, 255, 1);

}

.ant-input[disabled] {
  background-color: #EEE;
  color: #444444;
}

.ant-input-number-disabled {
  background-color: #EEE;
  color: #444444;
}

.ant-menu-submenu-title:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover:hover {
  border-bottom: none;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #AAA;
}
.ant-select-disabled * {
  color: #0A0A0A;
}
.ant-select-selection-placeholder {
  color: #0A0A0A;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 16px;
}
/*@media (max-width: 1440px) {*/
/*  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {*/
/*    height: 32px;*/
/*    line-height: 32px;*/
/*  }*/
/*}*/
/*@media (min-width: 1441px) {*/
/*  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {*/
/*    height: 48px;*/
/*    line-height: 48px;*/
/*  }*/
/*}*/
/*@media (max-width: 1440px) {*/
/*  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {*/
/*    height: 32px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*  }*/
/*}*/
/*@media (min-width: 1441px) {*/
/*  .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {*/
/*    height: 48px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*  }*/
/*}*/

.ant-table.ant-table-small .ant-table-tbody > tr > td {
  padding: 0;
}

.ant-menu-submenu-popup {
  z-index: 10000000;
  position: fixed;
}

/*.ant-image-preview-wrap {*/
/*  z-index: 9000000;*/
/*}*/

.ant-image-preview-operations {
  background-color: #0A0A0A;
  height: 48px;
}

.ant-table-cell[colspan]::after {
  content: '';
  width: 98%;
  height: 2px;
  background-color: lightgrey;
  position: absolute;
  bottom: 1px;
  left: 1%;
}

/*.ant-dropdown {*/
/*  width: 600px !important;*/
/*  position: fixed !important;*/
/*}*/
.ant-select-dropdown {
  position: fixed !important;
}

@keyframes scroll {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-7vw);
  }
}
.ant-space-compact-block {
  width: unset;
}

.ant-modal-header {
  border-radius: 12px;
}

.ant-modal-content {
  border-radius: 12px;
}

.ant-modal-mask {
  z-index: 10000;
}

.ant-modal-wrap {
  z-index: 10000;
}


.ant-dropdown-menu {
  max-height: 200px;
  overflow: auto;
}
/*.ant-dropdown-menu-item {*/
/*  padding: 0;*/
/*}*/

.tooltip-overlay {
  max-width: 300px;
  word-wrap: break-word;
}

.search-select-dropdown {
  width: 600px;
}

.rc-virtual-list {
  width: 600px;
}

.ant-select-item {
  padding: 0 12px;
}
.ant-select-dropdown {
  z-index: 10000000;
}
.ant-drawer {
  z-index: 1001;
}
.ant-drawer-header-title {
  flex: unset;
  margin-right: 24px;
}
.ant-drawer-extra {
  flex-grow: 2;
}
.ant-menu.ant-menu-inline-collapsed {
  width: 58px;
  padding: 6px 0;
  padding-bottom: 6px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.ant-menu-dark.ant-menu-inline {
  padding: 6px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu-light.ant-menu-inline {
  padding: 6px 0;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0 24px;
}

.ant-picker-dropdown {
  z-index: 9000000;
}
* {
  font-family: 'Red Hat Display', 'Helvetica Neue', sans-serif !important;
}

.child-part-unique-term {
  font-weight: 500;
  background-color: #fffeb0;
}
.child-part-same-term {
  font-weight: 400;
  /*color: #0A0A0A;*/
  color: rgba(0, 0, 0, 0.4);
}

.active-selector-active-label {
  background-color: #FF9800;
  color: #ffeacc;
  font-weight: bold;
  padding: 0 4px;
  border-radius: 4px;
}
